$(document).ready(initPage);

function initPage() {
  /*** Common ***/

  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }
  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-menu');
  });

  $(
    '.header-nav .menu-item-has-children > a, .header-nav .menu-item-has-children > span'
  ).click(function () {
    event.preventDefault();
    $(this).parent().toggleClass('is-expanded');
    $(this).parent().siblings().removeClass('is-expanded');
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.header-nav').length) {
      $('.header-nav .menu-item-has-children').removeClass('is-expanded');
    }
  });

  $('select').selectric({
    // responsive: true,
  });

  /*** Home ***/

  $(".feature-item:not('is-active')").click(function () {
    const activeTab = $(this).data('tab');
    $(this).siblings().removeClass('is-active');
    $(this).addClass('is-active');
    $('.feature-desc-item').removeClass('is-active');
    $(`.feature-desc-item[data-tab-content="${activeTab}"]`).addClass(
      'is-active'
    );
  });

  const jobSwiper = new Swiper('.swiper--job', {
    slidesPerView: 4,
    spaceBetween: 28,
    loop: true,
    // centeredSlides: true,
    navigation: {
      prevEl: '.section-search .swiper-prev',
      nextEl: '.section-search .swiper-next',
    },
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1600: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
      2000: {
        slidesPerView: 5,
        spaceBetween: 28,
      },
    },
  });
  const newsSwiper = new Swiper('.swiper--news', {
    slidesPerView: 3,
    spaceBetween: 34,
    loop: true,
    navigation: {
      prevEl: '.section-news .swiper-prev',
      nextEl: '.section-news .swiper-next',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 34,
      },
    },
  });

  const testimonialsSwiper = new Swiper('.swiper--testimonials', {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    navigation: {
      prevEl: '.section-testimonials .swiper-prev',
      nextEl: '.section-testimonials .swiper-next',
    },
  });

  $('.details-item .details-h').click(function () {
    $(this).parents('.details-item').toggleClass('is-active');
  });

  /* Jobs */

  $('.filter-clear').click(function () {
    $('.search-filters select').each(function () {
      $(this).val('');
      $(this).selectric('refresh');
    });
  });

  $('.job-list .card--job').click(function () {
    $('.job-list .card--job').removeClass('is-active');
    $(this).addClass('is-active');
    $('.jobs-b-i').addClass('is-open');
    setTimeout(function () {
      $('html, body').animate(
        {
          scrollTop: $('.jobs-r').offset().top - 70,
        },
        500
      );
    }, 500);
  });
  $('.jobs-r .back').click(function () {
    $('.jobs-b-i').removeClass('is-open');
  });
}
